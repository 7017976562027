import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

// Système de Design de l'État
import {
  Logo,
  Service,
  Tool,
  ToolItem,
  ToolItemGroup,
  Text,
  Container,
  Row,
  Col,
} from '@dataesr/react-dsfr';

import UserService from './../services/user.service';
import AuthService from './../services/auth.service';
import eventBus from '../eventBus';
import { gestionProfilsBaseName } from '../configs/routes';
import PdfHelper from '../helpers/PdfHelper';

export default class AppHeader extends Component {
  constructor(props) {
    super(props);
    const user = UserService.getCurrentUser();
    this.state = {
      fullname: user ? user.name : '',
      role: user ? this.getRoleLabel(user.effective_profil) : '',
    };

    eventBus.on('userSet', (user) => {
      this.setState({
        fullname: user.name,
        role: this.getRoleLabel(user.effective_profil),
      });
    });
  }

  getRoleLabel(effectiveProfil) {
    switch (effectiveProfil.role) {
      case 'ADMINISTRATEUR_GENERAL':
        return this.getRoleLabelForAdminGen(effectiveProfil);
      case 'ADMINISTRATEUR':
        return this.getRoleLabelForAdmin(effectiveProfil);
      case 'GESTIONNAIRE':
        return this.getRoleLabelForGestionnaire(effectiveProfil);
      case 'CONSULTANT':
        return this.getRoleLabelForConsultant(effectiveProfil);
      default:
        return 'Profil inconnu';
    }
  }

  getRoleLabelForAdminGen(effectiveProfil) {
    return (
      effectiveProfil.role_label +
      ' ' +
      effectiveProfil.application_context_label
    );
  }

  getRoleLabelForAdmin(effectiveProfil) {
    const prefix = 'Administrateur ';

    if (effectiveProfil.application_context === 'ICPE') {
      return prefix + effectiveProfil.application_context;
    }

    if (effectiveProfil.application_context === 'EAU_ET_NATURE') {
      if (effectiveProfil.types_commissionnement.length === 1) {
        return prefix + 'Inspection DGPR';
      }

      if (effectiveProfil.types_commissionnement.length === 4) {
        return prefix + 'Inspection DGALN, Réserves, CT, Littoral';
      }

      return 'Le nombre de type de commissionnement ne correspond à aucun profil "Administrateur"';
    }

    return 'Application non reconnue';
  }

  getRoleLabelForGestionnaire(effectiveProfil) {
    const prefix = 'Gestionnaire ';

    if (effectiveProfil.application_context === 'ICPE') {
      const sousOrganisme = effectiveProfil.sous_organisme ?? 'France';
      return prefix + effectiveProfil.application_context + ' ' + sousOrganisme;
    }

    if (effectiveProfil.application_context === 'EAU_ET_NATURE') {
      if (effectiveProfil.types_commissionnement[0] === 'Inspection DGPR') {
        const sousOrganisme = effectiveProfil.sous_organisme ?? 'France';
        return (
          prefix +
          effectiveProfil.types_commissionnement[0] +
          ' ' +
          sousOrganisme
        );
      }

      if (effectiveProfil.types_commissionnement[0] === 'Inspection DGALN') {
        if (effectiveProfil.organisme_gestionnaire === 'DREAL') {
          const sousOrganisme = effectiveProfil.sous_organisme ?? 'France';
          return (
            prefix +
            effectiveProfil.types_commissionnement[0] +
            ' ' +
            sousOrganisme
          );
        }

        if (
          effectiveProfil.organisme_gestionnaire ===
          'Office français de la biodiversité'
        ) {
          return prefix + 'OFB';
        }

        if (effectiveProfil.organisme_gestionnaire.includes('PN')) {
          return prefix + effectiveProfil.organisme_gestionnaire;
        }

        return 'Cet organisme gestionnaire ne correspond à aucun profil "Gestionnaire"';
      }

      if (
        effectiveProfil.types_commissionnement[0] === 'Agents des réserves' ||
        effectiveProfil.types_commissionnement[0] === 'Gardes du littoral'
      ) {
        return prefix + effectiveProfil.organisme_gestionnaire;
      }

      if (
        effectiveProfil.types_commissionnement[0] ===
        'Agents des collectivités territoriales'
      ) {
        if (
          effectiveProfil.organisme_gestionnaire === 'Conseils départementaux'
        ) {
          const sousOrganisme = effectiveProfil.sous_organisme ?? 'France';
          return prefix + ' Conseil Départemental ' + sousOrganisme;
        }

        if (effectiveProfil.organisme_gestionnaire.includes('PNR')) {
          return prefix + effectiveProfil.organisme_gestionnaire;
        }

        return 'Aucun profil "Agents des collectivités territoriales" ne correspond';
      }

      return 'Le(s) type(s) de commissionnement ne correspond à aucun profil "Gestionnaire"';
    }

    return 'Application non reconnue';
  }

  getRoleLabelForConsultant(effectiveProfil) {
    const sousOrganisme = effectiveProfil.sous_organisme ?? 'France';

    return (
      'Consultant ' +
      effectiveProfil.application_context_label +
      ' ' +
      sousOrganisme
    );
  }

  logout() {
    eventBus.dispatch('loggingOut');
    axios
      .get(`/logout/${AuthService.getId()}`)
      .then((_) => {
        AuthService.clearData();
        window.location.href = process.env.REACT_APP_CERBERE_LOGOUT_URL;
      })
      .catch((_) => {
        eventBus.dispatch('loggingOutFailed');
      });
  }

  render() {
    return (
      <header role="banner" className="fr-header fr-app-header">
        <div className="fr-header__body">
          <div className="fr-container">
            <div className="fr-header__body-row">
              <div className="fr-header__brand fr-enlarge-link">
                <div className="fr-header__brand-top">
                  <Logo className="fr-app-logo" splitCharacter={9}>
                    Ministère de la Transition Écologique et de la Cohésion des
                    Territoires
                  </Logo>
                </div>
                <Service
                  title="Commissionnement"
                  description={process.env.REACT_APP_VERSION ?? '0.0.0'}
                />
              </div>
              <div className="fr-header__tools">
                <div className="fr-header__tools-links">
                  <Tool>
                    <ToolItemGroup>
                      {UserService.isGestionnaire() ? (
                        <ToolItem
                          className="fr-app-btn-icon guide-photos"
                          icon="ri-book-line"
                          onClick={(event) =>
                            PdfHelper.downloadManuelUtilisateur(event)
                          }
                        >
                          Manuel utilisateur
                        </ToolItem>
                      ) : (
                        <></>
                      )}
                      {UserService.isAdministrateur() ||
                      UserService.isGestionnaire() ? (
                        <ToolItem
                          className="fr-app-btn-icon guide-photos"
                          icon="ri-file-user-line"
                          link={
                            process.env.REACT_APP_API_BASE_URL +
                            '/manuel_photos/files/assets/basic-html/page-1.html'
                          }
                          target="_blank"
                        >
                          Guide photos
                        </ToolItem>
                      ) : (
                        <></>
                      )}
                      {UserService.isAdministrateur() ? (
                        <ToolItem
                          className="fr-app-btn-icon"
                          icon="ri-settings-3-line"
                          link="/parametrage"
                        >
                          Paramétrage
                        </ToolItem>
                      ) : (
                        ' '
                      )}
                      {/* TODO hidden for release */}
                      {/*<ToolItem link="/path" className="link-quick_access">*/}
                      {/*  Accès rapides*/}
                      {/*</ToolItem>*/}
                    </ToolItemGroup>
                  </Tool>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fr-header__menu">
          <Container>
            <Row>
              <Col className="fr-col-xs-12 fr-col-sm-8 fr-col-md-8 fr-col-lg-9 fr-col-xl-10">
                <div className="fr-app-user-info">
                  <span
                    type="button"
                    className="fr-app-btn_with_icon_v_header btn-menu on"
                  >
                    <span className="ri-menu-2-line" aria-hidden="true" />
                    <span>Menu</span>
                  </span>
                  <Text size="md">
                    {this.state.role} - {this.state.fullname}
                  </Text>
                </div>
              </Col>
              <Col className="fr-col-xs-12 fr-col-sm-4 fr-col-md-4 fr-col-lg-3 fr-col-xl-2 fr-app-user-actions">
                {UserService.canSwitchProfil() ? (
                  <Link
                    to={gestionProfilsBaseName}
                    className="fr-app-btn-icon fr-app-btn_with_icon_v_header btn-profile_list dff"
                  >
                    <span className="ri-group-line" aria-hidden="true" />
                    <br />
                    <span>Profils</span>
                  </Link>
                ) : (
                  ''
                )}
                <button
                  onClick={() => this.logout()}
                  type="button"
                  className="fr-app-btn-icon fr-app-btn_with_icon_v_header btn-logout"
                >
                  <span
                    className="ri-logout-circle-r-line"
                    aria-hidden="true"
                  />
                  <br />
                  <span>Déconnexion</span>
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    );
  }
}
