import axios from 'axios';
import { saveAs } from 'file-saver';
import { arretesRoutesAPI } from '../configs/routesAPI';
import UserService from '../services/user.service';

class PdfHelper {
  downloadFichePoste = (posteId, event) => {
    event.preventDefault();
    axios({
      url: '/poste/fiche-poste/' + posteId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, `Fiche_poste_${posteId}.pdf`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadManuelUtilisateur = (event) => {
    event.preventDefault();
    axios({
      url: '/manuel-utilisateur',
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          let filename = '';
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });

          if (UserService.isGestionnaire()) {
            if (UserService.isIcpe()) {
              filename = 'Commissionnement-Manuel_ICPE_Gestionnaire_v1.2.pdf';
            } else {
              if (UserService.isTypeCommissionnementDGPR()) {
                filename = 'Commissionnement-Manuel_OH_Gestionnaire_v1.0.pdf';
              }
              if (UserService.isTypeCommissionnementDGALN()) {
                filename = 'Commissionnement-Manuel_E&N_Gestionnaire_v1.0.pdf';
              }
            }
          }

          saveAs(blob, filename);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadJustificatifNote = (noteId, filename, event) => {
    event.preventDefault();
    axios({
      url: '/agent/notes/justificatif/' + noteId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          saveAs(blob, `Justificatif_note_${filename}`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadJustificatifFOAD = (foadId, event) => {
    event.preventDefault();
    axios({
      url: '/formations/foad/justificatif/' + foadId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, `Justificatifs_FOAD.pdf`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadJustificatifHabilitation = (agentHabilitationId, filename) => {
    axios({
      url: '/habilitations/justificatif/' + agentHabilitationId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf;',
      });
      let name = filename ? filename + '.pdf' : 'Justificatif_habilitation.pdf';
      saveAs(blob, name);
    });
  };

  downloadPieceJointeActionEnCours = (actionEnCoursId, filename) => {
    axios({
      url: '/commissionnements/actions/pdf/' + actionEnCoursId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf;',
      });
      let name = filename ? filename + '.pdf' : 'justificatif.pdf';
      saveAs(blob, name);
    });
  };

  downloadPrestationDeSerment = (prestationSermentId, filename) => {
    axios({
      url: '/prestations-serment/pdf/' + prestationSermentId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf;',
      });
      let name = filename ? filename + '.pdf' : 'prestation_de_serment.pdf';
      saveAs(blob, name);
    });
  };

  downloadArreteNomination = (
    arreteNominationId,
    numeroNor,
    event,
    historiqueId = null
  ) => {
    const url =
      null !== historiqueId
        ? arretesRoutesAPI.getArreteNominationPdfForHistorique.replace(
            ':idHistorique',
            historiqueId
          )
        : arretesRoutesAPI.getArreteNominationPdf.replace(
            ':idArreteNomination',
            arreteNominationId
          );

    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, `${numeroNor}.pdf`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadCartesBordereau = (bordereauId, filename, event) => {
    event.preventDefault();
    axios({
      url: '/cartes-commissionnement/pdf-bordereau/' + bordereauId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, filename);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };
}

export default new PdfHelper();
