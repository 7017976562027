import axios from 'axios';
import React, { useState, useEffect } from 'react';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import { stats } from '../../configs/routesAPI';
import { saveAs } from 'file-saver';
import {
  ButtonGroup,
  Col,
  Container,
  Row,
  SideMenu,
  SideMenuItem,
} from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';
import SelectAnnee from '../../components/shared/selects/SelectAnnee';
import { useForm } from 'react-hook-form';
import { localStorageSearchKey } from '../../configs/localStorageSearchKey';

export default function PagesStatsAgentsNonInscritsAnneeListe() {
  const [agentsFormationsAnnee, setAgentsFormationsAnnee] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const currentYear = new Date().getFullYear();
  const mois = {
    janvier: 'Janvier',
    fevrier: 'Février',
    mars: 'Mars',
    avril: 'Avril',
    mai: 'Mai',
    juin: 'Juin',
    juillet: 'Juillet',
    aout: 'Août',
    septembre: 'Septembre',
    octobre: 'Octobre',
    novembre: 'Novembre',
    decembre: 'Décembre',
  };

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      annee: currentYear - 1,
    },
  });

  const params = {
    annee: null,
  };

  useEffect(() => {
    // Initialisation des valeurs du formulaires
    let inputParam = localStorage.getItem(
      localStorageSearchKey.searchStatistiquesListe
    );
    if (inputParam !== null) {
      inputParam = JSON.parse(inputParam);
      setValue('annee', inputParam.annee);
    }
    getData();
  }, []);

  const getData = () => {
    document.title =
      'Synthèse des agents arrivés en poste en ' +
      getValues('annee') +
      ' non inscrits à la semaine métier, à la semaine technique ou à la semaine consolidation  | Commissionnement';
    params.annee = getValues('annee');
    localStorage.setItem(
      localStorageSearchKey.searchStatistiquesListe,
      JSON.stringify(params)
    );
    axios
      .get(stats.getAgentsNonInscrits, { params })
      .then((response) => {
        setAgentsFormationsAnnee(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
    getData();
  };

  const transformToLabel = (key) => {
    switch (key) {
      case 'semaine_metier':
        return 'Semaine métier';
      case 'semaine_technique':
        return 'Semaine technique';
      case 'semaine_consolidation':
        return 'Semaine consolidation';
      default:
        return 'Clé inconnue';
    }
  };

  const downloadCsv = (event) => {
    event.preventDefault();
    const annee = getValues('annee');
    params.annee = annee;

    axios({
      url: stats.exportAgentsNonInscrits,
      params: params,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });
      saveAs(
        blob,
        'Tableau_bord_suivi_agents_non_inscrits_prise_de_poste_' +
          annee +
          '.xlsx'
      );
    });
  };

  return isLoading ? (
    <LoaderCommissionnement />
  ) : (
    <>
      <div>
        {!isLoading ? <TechnicalError error={apiError} /> : <></>}

        <section className="fr-app-section">
          <form className="fr-app-form">
            <Container fluid>
              <Row className={'align-center'}>
                <Col n="3">
                  <SelectAnnee
                    register={register}
                    handleChange={handleChange}
                    anneeMin={2010}
                    anneeMax={currentYear}
                  ></SelectAnnee>
                </Col>
              </Row>
            </Container>
          </form>

          <div className="fr-table fr-app-table no-padding-top">
            <caption style={{ display: 'block' }}>
              Synthèse des agents arrivés en poste en {getValues('annee')} non
              inscrits à la semaine métier, à la semaine technique ou à la
              semaine consolidation
            </caption>
            {isLoading ? (
              <LoaderCommissionnement className={'loader-center'} />
            ) : (
              <>
                <SideMenu
                  className="fr-app-main-nav"
                  buttonLabel="Menu"
                  sticky={false}
                >
                  {Object.entries(agentsFormationsAnnee).map((data) => {
                    return (
                      <SideMenuItem
                        title={transformToLabel(data[0])}
                        className={'fr-sidemenu__item fr-app-menu-item off'}
                      >
                        <div className="tableContainer">
                          <table className="table-stats-agents-non-inscrits">
                            <thead style={{ top: '0rem' }}>
                              <tr>
                                <th scope="col" key={'zone'}></th>
                                <th scope="col" key={'nb_agents'}>
                                  Nb agents
                                </th>
                                {Object.keys(mois).map((key) => {
                                  return (
                                    <th scope="col" key={key}>
                                      {mois[key]}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {data[1].map((ligne) => {
                                return (
                                  <tr>
                                    <td>{ligne.region}</td>
                                    <td>{ligne.nb_agents}</td>
                                    {Object.keys(mois).map((key) => {
                                      return <td key={key}>{ligne[key]}</td>;
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </SideMenuItem>
                    );
                  })}
                </SideMenu>
              </>
            )}
          </div>

          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Link
              type="button"
              className="fr-btn--md fr-btn btn-action-trigger"
              title="Export CSV"
              onClick={downloadCsv}
              to={''}
              disabled={isLoading}
              download
            >
              Export CSV
            </Link>
          </ButtonGroup>
        </section>
      </div>
    </>
  );
}
